export default [
    {
      title: "Registra Account",
      icon: "UserIcon",
      route: "backend_salepoint_register",
    },
    {
      title: "Elenco Account",
      icon: "ListIcon",
      route: "backend_salepoint_list",
    },
  ];
  